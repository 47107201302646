import React from "react";
import Box from "@mui/material/Box";

interface FullScreenBoxProps {
  children: React.ReactNode;
  style?: React.CSSProperties; // Add this line
}

const FullScreenBox: React.FC<FullScreenBoxProps> = ({ children, style }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "80vh",
        alignItems: "center",
        marginTop: 12,
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default FullScreenBox;
