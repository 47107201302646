import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CoolAppBar from "../components/AppBar";
import FullScreenBox from "../components/FullScreenBox";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { Link } from "@mui/material";
import PageTitle from "../components/PageTitle";

const PatchNotes = () => {
  const theme = useTheme();
  return (
    <FullScreenBox>
      <CoolAppBar />
      <PageTitle title="Patch Notes" />
      <Container component="main" maxWidth="sm">
        <Typography variant="h5">What's New</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          fontSize="0.75rem"
          sx={{ fontStyle: "italic" }}
          gutterBottom
        >
          (vWeAreSoBack - Mar 24, 2024)
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Typography variant="h4">🚧</Typography>
            </ListItemAvatar>
            <Typography variant="body1">This entire website</Typography>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Typography variant="h4">📞</Typography>
            </ListItemAvatar>
            <Typography variant="body1">
              Log in with only your phone number and a OTP
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Typography variant="h4">🚫</Typography>
            </ListItemAvatar>
            <Typography variant="body1">
              All accounts made with username and email unfortunately had to be
              deleted (sorry)
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Typography variant="h4">🔗</Typography>
            </ListItemAvatar>
            <Typography variant="body1">
              Plugs now have a sharable link that should take you directly to
              the plug if you have the app installed or just open it in your
              browser
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Typography variant="h4">👥</Typography>
            </ListItemAvatar>
            <Typography variant="body1">
              There is no longer a friends list. On mobile you can invite people
              directly from your contacts list.
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Typography variant="h4">🐛</Typography>
            </ListItemAvatar>
            <Typography variant="body1">
              Probably A LOT of bugs (forgive me, working on it)
            </Typography>
          </ListItem>
        </List>
        <Divider
          variant="fullWidth"
          sx={{ bgcolor: theme.palette.action.disabled }}
        />
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            Upcoming
          </Typography>
          <Typography variant="body1">- For now, just bug fixes.</Typography>
          <Typography variant="body1" gutterBottom>
            - Actually, perhaps autocomplete for addresses
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            fontSize="0.75rem"
            sx={{ fontStyle: "italic" }}
          >
            Please{" "}
            <Link href="mailto:hello@pluggo.app?subject=Plug%20Suggestions%20/%20Feedback">
              email us
            </Link>{" "}
            any requests or suggestions
          </Typography>
        </Box>
        <Divider
          variant="fullWidth"
          sx={{ bgcolor: theme.palette.action.disabled }}
        />
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            Mobile App Exclusive Features
          </Typography>
          <Typography variant="body1">- Much better UI ❤️</Typography>
          <Typography variant="body1">- See your Plugs on a map</Typography>
          <Typography variant="body1">- Interact with PlugPoints</Typography>
          <Typography variant="body1">
            - Create and reuse Plug templates
          </Typography>
          <Typography variant="body1">
            - Create groups of your friends to share plugs with
          </Typography>
          <Typography variant="body1">
            - Google maps auto complete for addresses
          </Typography>
          <br />
          <Typography variant="body1">
            🔥 Try it on{" "}
            <Link
              href="https://apps.apple.com/us/app/plug/id1473681218"
              target="_blank"
              underline="none"
              style={{ color: theme.palette.hyperlink.main }}
            >
              iOS
            </Link>{" "}
            or{" "}
            <Link
              href="https://play.google.com/store/apps/details?id=com.pluggo.plugapp"
              target="_blank"
              underline="none"
              style={{ color: theme.palette.hyperlink.main }}
            >
              Android
            </Link>
          </Typography>
        </Box>{" "}
        <Divider
          variant="fullWidth"
          sx={{ bgcolor: theme.palette.action.disabled }}
        />
        <Box sx={{ my: 4 }}>
          <Typography variant="h5">RSVP Decoder</Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            fontSize="0.75rem"
            sx={{ fontStyle: "italic" }}
            gutterBottom
          >
            (Feel free to{" "}
            <Link href="mailto:hello@pluggo.app?subject=Plug%20RSVP%20Suggestions">
              share
            </Link>{" "}
            RSVP decoder suggestions for the next release)
          </Typography>
          <Typography variant="body1">
            - <strong>Going:</strong> Yes
          </Typography>
          <Typography variant="body1">
            - <strong>Likely:</strong> Less enthusiastic yes
          </Typography>
          <Typography variant="body1">
            - <strong>Maybe:</strong> Probably not
          </Typography>
        </Box>
      </Container>
    </FullScreenBox>
  );
};

export default PatchNotes;
