import { useState } from "react";
import * as React from "react";
import { useAuth } from "../contexts/AuthContext";
import { usePlugs } from "../contexts/PlugsContext";
import FullScreenBox from "../components/FullScreenBox";
import { useNavigate } from "react-router-dom";
import PlugsList from "../components/PlugsList";
import PageTitle from "../components/PageTitle";

interface Plug {
  creatorID: string;
  creatorUsername: string;
  icon: string;
  title: string;
  date: Date;
  placeName: string;
  placeAddress?: string;
  startTime: Date;
  endTime: Date;
  forwardedBy?: Record<string, string>;
  description?: string;
  friendsInvited: string[];
  friendsRSVP: {
    [username: string]: "accepted" | "likely" | "maybe" | "pending" | "ignored";
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  forwarding: boolean;
  guestStatus: boolean;
  id: string;
  longitude?: string | number;
  latitude?: string | number;
  plugPoint: boolean;
}

const PastPlugs = () => {
  const { createdPlugs, receivedPlugs } = usePlugs();
  const { user } = useAuth();
  const [activeList, setActiveList] = useState("all");
  const navigate = useNavigate();

  if (!user) {
    navigate("/login");
    return null;
  }

  const getCreatorDisplayName = (plug: Plug): string => {
    if (plug.creatorID === user.uid) {
      // The user is the creator of the plug
      return "";
    } else if (plug.plugPoint === true) {
      // The plug is a PlugPoint
      return `| ${plug.creatorUsername}`;
    }
    // If we can't determine the creator for some reason, return an empty string
    return "";
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveList(newValue);
  };

  let plugsToShow: Plug[] = [];
  switch (activeList) {
    case "all":
      plugsToShow = [...createdPlugs, ...receivedPlugs];
      break;
    case "created":
      plugsToShow = createdPlugs;
      break;
    case "received":
      plugsToShow = receivedPlugs;
      break;
  }

  plugsToShow = plugsToShow.filter(
    (plug) => plug.status === "ended" || user.ignoredPlugs?.includes(plug.id)
  );

  plugsToShow.sort((a, b) => a.date.getTime() - b.date.getTime());

  return (
    <FullScreenBox>
      <PageTitle title="Past Plugs" />
      <PlugsList
        plugs={plugsToShow}
        activeList={activeList}
        handleChange={handleChange}
        getCreatorDisplayName={getCreatorDisplayName}
      />
    </FullScreenBox>
  );
};

export default PastPlugs;
