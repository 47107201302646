import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CoolAppBar from "../components/AppBar";
import FullScreenBox from "../components/FullScreenBox";
import { Box } from "@mui/material";
import DeleteAccountPic from "../assets/DeleteAccount.png";

const DeleteAccount = () => {
  return (
    <FullScreenBox>
      <CoolAppBar />
      <Container component="main" maxWidth="sm">
        <Box textAlign="center">
          <Typography variant="h5" gutterBottom>
            How to Delete Your Account Using the Mobile App
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ mb: 2, mt: 5 }}>
          To delete your account, press the “permanent actions” button on the
          Profile screen and then press the “Delete” button.
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          This will delete your account, plugs you created and kept in your
          history, templates you created, and groups you created.
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          (You can also email hello@pluggo.app for assistance with deleting some
          or all of your data)
        </Typography>
        <img
          src={DeleteAccountPic}
          alt="Delete account screen on mobile"
          style={{ width: "100%", maxWidth: "500px" }}
        />
      </Container>
    </FullScreenBox>
  );
};

export default DeleteAccount;
