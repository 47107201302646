import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FullScreenBox from "../components/FullScreenBox";
import CoolAppBar from "../components/AppBar";
import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { doc, updateDoc } from "firebase/firestore";
import { FIREBASE_AUTH, FIREBASE_DB } from "../firebase";
import { deleteUserAccount } from "../utils/cloudFunctions";
import { signOut } from "firebase/auth";
import {
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";
import PageTitle from "../components/PageTitle";

const AccountManagement = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [displayName, setDisplayName] = useState(user ? user.displayName : "");
  const [icon, setIcon] = useState(user ? user.icon : "");
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const theme = useTheme();

  if (!user) {
    navigate("/login");
    return null;
  }

  const handleSave = async () => {
    if (displayName && icon) {
      setLoading(true);
      await updateDoc(doc(FIREBASE_DB, "users", user.uid), {
        displayName,
        icon,
      });
      setLoading(false);
      setEditMode(false);
    }
  };

  const handleCancelSave = () => {
    setDisplayName(user.displayName);
    setIcon(user.icon);
    setEditMode(false);
  };

  const formatPhoneNumberIntl = (phoneNumberString: string) => {
    const phoneNumber = parsePhoneNumber(phoneNumberString, "US");
    return phoneNumber ? phoneNumber.formatInternational() : null;
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const deleteUserDocument = async () => {
    setLoading(true);
    try {
      await deleteUserAccount(user.uid);
      await signOut(FIREBASE_AUTH);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
    setLoading(false);
  };

  const emojiList = [
    "😃",
    "😄",
    "😅",
    "🙃",
    "🔌",
    "🚀",
    "🥑",
    "🥶",
    "🌽",
    "🍿",
    "⛳️",
    "🍞",
    "🥖",
    "🧠",
    "🤑",
    "🔥",
    "📍",
    "🫡",
    "🫵",
    "🦦",
    "🧼",
    "🧽",
    "✈️",
    "🚁",
    "🛬",
    "👀",
    "🙌",
    "🌳",
    "🌱",
    "🫧",
  ];

  return (
    <FullScreenBox>
      <CoolAppBar />
      <PageTitle title="Account Management" />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 4,
            width: "100%",
          }}
        >
          <Typography variant="h5">User Account:</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 3,
            }}
          >
            {editMode ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Select
                      value={icon}
                      onChange={(e) => setIcon(e.target.value as string)}
                    >
                      {emojiList.map((emoji, index) => (
                        <MenuItem key={index} value={emoji}>
                          {emoji}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      label="Display Name"
                      variant="outlined"
                      fullWidth
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCancelSave}
                      fullWidth
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSave}
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : "Save"}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h3">{user.icon}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    ml: 2,
                    mr: 2,
                  }}
                >
                  <Box>
                    <Typography variant="h6">{user.displayName}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      {formatPhoneNumberIntl(user.uid)}
                    </Typography>
                  </Box>
                </Box>
                <IconButton onClick={() => setEditMode(true)}>
                  <EditIcon />
                </IconButton>
              </>
            )}
          </Box>
          <Typography variant="h5">PlugPoints:</Typography>
          <Typography variant="body1">
            These are accounts that can be followed and all plugs will be shared
            the accounts following. You can create your own or follow other
            PlugPoint accounts.
          </Typography>
          <Typography variant="body1">
            Right now, PlugPoints are only available on the{" "}
            <Box
              component={Link}
              to="/"
              sx={{ color: theme.palette.hyperlink.main }}
            >
              mobile app
            </Box>
            . Apologies.
          </Typography>
          <Box sx={{ marginTop: 10 }}>
            <Button
              variant="contained"
              color="warning"
              onClick={openDeleteDialog}
              disabled={loading}
              fullWidth
            >
              Delete Plug Account
            </Button>
          </Box>
          <Typography
            color="text.secondary"
            fontSize="0.75rem"
            sx={{ fontStyle: "italic", textAlign: "center" }}
          >
            "If it is to be said, so it be, so it is."
          </Typography>
          <Dialog
            open={deleteDialogOpen}
            onClose={closeDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete Plug Account"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete your Plug account and all
                associated user data? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeDeleteDialog}
                color="primary"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                onClick={deleteUserDocument}
                color="error"
                autoFocus
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Permanently Delete"
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </FullScreenBox>
  );
};

export default AccountManagement;
