import { getFunctions, httpsCallable } from "firebase/functions";
import { FIREBASE_APP } from "../firebase";
import { Plug } from "../types/types";

interface FunctionResult {
  success: boolean;
}

const functions = getFunctions(FIREBASE_APP);

export const testFunction = () => {
  console.log("Test function was called");
};

export const sendVerificationCode = async (phoneNumber: string) => {
  const sendVerificationCodeFunction = httpsCallable(
    functions,
    "sendVerificationCode"
  );
  try {
    const result = await sendVerificationCodeFunction({ phoneNumber });
    return result.data;
  } catch (error) {
    console.error("Error sending verification code:", error);
    throw error;
  }
};

export const checkVerificationCode = async (
  phoneNumber: string,
  code: string
) => {
  const checkVerificationCodeFunction = httpsCallable(
    functions,
    "checkVerificationCode"
  );
  try {
    const result = await checkVerificationCodeFunction({ phoneNumber, code });
    return result.data;
  } catch (error) {
    console.error("Error checking verification code:", error);
    throw error;
  }
};

export const deleteUserAccount = async (userId: string) => {
  const deleteUserAccountFunction = httpsCallable(
    functions,
    "deleteUserAccount"
  );
  try {
    const result = await deleteUserAccountFunction({ userId });
    const data = result.data as FunctionResult;
    if (data.success) {
      console.log("User account deleted successfully");
    } else {
      console.log("Failed to delete user account");
    }
  } catch (error) {
    console.error("Error deleting user account:", error);
  }
};

export const getEmailByUsername = async (username: string) => {
  const getEmailByUsernameFunction = httpsCallable(
    functions,
    "getEmailByUsername"
  );
  try {
    const result = await getEmailByUsernameFunction({ username });
    return (result.data as { email: string }).email;
  } catch (error) {
    console.error("Getting email by username failed", error);
    throw error;
  }
};

export const checkUsernameExists = async (username: string) => {
  const checkUsernameExistsFunction = httpsCallable(
    functions,
    "checkUsernameExists"
  );
  try {
    const result = await checkUsernameExistsFunction({ username });
    return (result.data as { exists: boolean }).exists;
  } catch (error) {
    console.error("Checking username existence failed", error);
    throw error;
  }
};

export const checkIfUserExists = async (phoneNumber: string) => {
  const checkIfUserExistsFunction = httpsCallable(
    functions,
    "checkIfUserExists"
  );
  try {
    const result = await checkIfUserExistsFunction({ phoneNumber });
    return (result.data as { exists: boolean }).exists;
  } catch (error) {
    console.error("Checking user existence failed", error);
    throw error;
  }
};

export const createUser = async (userData: {
  uid: string;
  username: string;
  displayName: string;
  icon: string;
  email: string;
}) => {
  const createUserFunction = httpsCallable(functions, "createUser");
  try {
    const result = await createUserFunction(userData);
    return (result.data as { success: boolean }).success;
  } catch (error) {
    console.error("User creation failed", error);
    throw error;
  }
};

export const createNewUser = async (userData: {
  phoneNumber: string;
  displayName: string;
  icon: string;
}) => {
  const createNewUserFunction = httpsCallable(functions, "createNewUser");
  try {
    const result = await createNewUserFunction(userData);
    return (result.data as { success: boolean }).success;
  } catch (error) {
    console.error("User creation failed", error);
    throw error;
  }
};

export const sendNotificationToUser = async (
  userId: string,
  username: string,
  title: string,
  body: string,
  screen: string,
  plug: Plug | null
) => {
  const sendPushNotification = httpsCallable(functions, "sendPushNotification");
  try {
    const result = await sendPushNotification({
      userId,
      username,
      title,
      body,
      data: { screen, plug },
    });
    const data = result.data as FunctionResult;
    if (data.success) {
      console.log("Notification sent successfully");
    } else {
      console.log("Failed to send notification");
    }
  } catch (error) {
    console.error("Error sending notification:", error);
  }
};
