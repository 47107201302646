import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface ChipColors {
    accepted: string;
    likely: string;
    maybe: string;
    pending: string;
    ignored: string;
    default: string;
  }

  interface Palette {
    hyperlink: Palette["primary"];
    subtext: Palette["primary"];
    chipColors: ChipColors;
  }

  interface PaletteOptions {
    hyperlink?: PaletteOptions["primary"];
    subtext?: PaletteOptions["primary"];
    chipColors?: ChipColors;
  }
}

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#1D1D1F",
      contrastText: "#F5F5F7",
    },
    secondary: {
      main: "rgb(240, 219, 255)",
      contrastText: "rgb(71, 12, 122)",
    },
    error: {
      main: "rgb(186, 26, 26)",
      contrastText: "rgb(255, 255, 255)",
    },
    background: {
      default: "#F5F5F7",
      paper: "rgb(255, 251, 255)",
    },
    text: {
      primary: "rgb(28, 28, 30)",
      secondary: "rgb(29, 27, 30)",
      disabled: "rgb(150, 150, 150)",
    },
    action: {
      disabledBackground: "rgba(29, 27, 30, 0.12)",
      disabled: "rgba(29, 27, 30, 0.38)",
    },
    hyperlink: {
      main: "#0000EE",
    },
    subtext: {
      main: "#808080",
    },
    chipColors: {
      accepted: "#3EB489",
      likely: "lightblue",
      maybe: "orange",
      pending: "lightgrey",
      ignored: "#yourColorHere",
      default: "#yourColorHere",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1D1D1F",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1D1D1F",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1D1D1F",
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "rgba(245, 245, 247, 0.9)",
      contrastText: "#1D1D1F",
    },
    secondary: {
      main: "rgb(95, 43, 146)",
      contrastText: "rgb(240, 219, 255)",
    },
    error: {
      main: "rgb(255, 180, 171)",
      contrastText: "rgb(105, 0, 5)",
    },
    background: {
      default: "#000000",
      paper: "rgb(29, 27, 30)",
    },
    text: {
      primary: "rgb(229, 229, 231)",
      secondary: "rgb(231, 225, 229)",
      disabled: "rgba(229, 229, 231, 0.38)",
    },
    action: {
      active: "rgba(245, 245, 247, 0.9)",
      disabledBackground: "rgba(231, 225, 229, 0.12)",
      disabled: "rgba(231, 225, 229, 0.38)",
    },
    hyperlink: {
      main: "#8ab4f8",
    },
    subtext: {
      main: "#808080",
    },
    chipColors: {
      accepted: "rgba(62, 180, 137, 0.5)",
      likely: "rgba(173, 216, 230, 0.4)",
      maybe: "rgba(255, 165, 0, 0.4)",
      pending: "rgb(77, 67, 87)",
      ignored: "#yourColorHere",
      default: "#yourColorHere",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(245, 245, 247, 0.3)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(245, 245, 247, 0.3)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(245, 245, 247, 0.3)",
          },
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
