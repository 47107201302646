import { createContext, useContext, useState, useEffect } from "react";
import { FIREBASE_DB } from "../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";
import { getRandomEmoji } from "../utils/emojiUtils";
import { addDays, addHours, isPast, startOfDay } from "date-fns";
import { PlugPoint } from "../types/types";

interface Plug {
  creatorID: string;
  creatorUsername: string;
  icon: string;
  title: string;
  date: Date;
  placeName: string;
  placeAddress?: string;
  startTime: Date;
  endTime: Date;
  forwardedBy?: Record<string, string>;
  description?: string;
  friendsInvited: string[];
  friendsInvitedUIDs: string[];
  friendsRSVP: {
    [username: string]: "accepted" | "likely" | "maybe" | "pending" | "ignored";
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  forwarding: boolean;
  guestStatus: boolean;
  id: string;
  longitude?: string | number;
  latitude?: string | number;
  plugPoint: boolean;
}

interface plugTemplates {
  creatorID: string;
  icon: string;
  title: string;
  placeName: string;
  placeAddress?: string;
  description?: string;
  friendsInvited: string[];
  groupsInvited?: string[];
  forwarding: boolean;
  guestStatus: boolean;
  longitude?: string | number;
  latitude?: string | number;
  id: string;
}

interface createPlugInputs {
  icon?: string;
  title?: string;
  placeName?: string;
  placeAddress?: string;
  description?: string;
  friendsInvited?: string[];
  groupsInvited?: string[];
  forwarding?: boolean;
  guestStatus?: boolean;
  longitude?: string | number;
  latitude?: string | number;
  plugPoint?: PlugPoint;
}

interface forwardedBy {
  [username: string]: string;
}

export const PlugsProvider = ({ children }: any) => {
  const [createdPlugs, setCreatedPlugs] = useState<Plug[]>([]);
  const [receivedPlugs, setReceivedPlugs] = useState<Plug[]>([]);
  const [chatMessages, setChatMessages] = useState({});
  const [unreadReceivedPlugs, setUnreadReceivedPlugs] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState<null | boolean>(null);
  const [prevReceivedPlugsCount, setPrevReceivedPlugsCount] = useState(0);
  const [plugTemplates, setPlugTemplates] = useState<plugTemplates[]>([]);
  const [forwardedBy, setForwardedBy] = useState<forwardedBy | null>(null);
  const { user } = useAuth();
  const defaultIcon = getRandomEmoji();
  const [createPlugInputs, setCreatePlugInputs] =
    useState<createPlugInputs | null>({
      icon: defaultIcon,
      title: "",
      placeName: "",
      placeAddress: "",
      description: "",
      friendsInvited: [],
      groupsInvited: [],
      forwarding: true,
      guestStatus: true,
      longitude: "",
      latitude: "",
    });
  const [savePlugInputs, setSavePlugInputs] = useState<createPlugInputs | null>(
    {
      icon: defaultIcon,
      title: "",
      placeName: "",
      placeAddress: "",
      description: "",
      friendsInvited: [],
      groupsInvited: [],
      forwarding: true,
      guestStatus: true,
      longitude: "",
      latitude: "",
    }
  );

  const updateChatMessages = (plugId: string, messagesCount: number) => {
    setChatMessages((prev) => ({ ...prev, [plugId]: messagesCount }));
  };

  useEffect(() => {
    if (user) {
      const plugsRef = collection(FIREBASE_DB, "plugs");
      const createdPlugsQuery = query(
        plugsRef,
        where("creatorID", "==", user.uid)
      );
      const unsubscribeCreated = onSnapshot(
        createdPlugsQuery,
        async (snapshot) => {
          let plugs: Plug[] = [];

          for (let doc of snapshot.docs) {
            const data = doc.data();
            const plug: Plug = {
              creatorID: data.creatorID,
              creatorUsername: data.creatorUsername,
              icon: data.icon,
              title: data.title,
              date: data.date ? data.date.toDate() : null,
              placeName: data.placeName,
              placeAddress: data.placeAddress,
              startTime: data.startTime ? data.startTime.toDate() : null,
              endTime: data.endTime ? data.endTime.toDate() : null,
              description: data.description,
              friendsInvited: data.friendsInvited,
              friendsInvitedUIDs: data.friendsInvitedUIDs,
              friendsRSVP: data.friendsRSVP,
              status: data.status,
              createdAt: data.plugCreatedAt
                ? data.plugCreatedAt.toDate()
                : null,
              updatedAt: data.plugUpdatedAt
                ? data.plugUpdatedAt.toDate()
                : null,
              forwarding: data.forwarding,
              forwardedBy: data.forwardedBy,
              guestStatus: data.guestStatus,
              id: doc.id,
              longitude: data.longitude,
              latitude: data.latitude,
              plugPoint: data.plugPoint,
            };
            if (plug.status === "active") {
              const fiveAMNextDay = addHours(
                startOfDay(addDays(plug.date, 1)),
                5
              );
              if (isPast(fiveAMNextDay)) {
                await updateDoc(doc.ref, {
                  // Here we pass the document reference directly
                  status: "ended",
                });
                plug.status = "ended";
              }
            }

            plugs.push(plug);
          }

          setCreatedPlugs(plugs);
        },
        (error) => {
          console.error("Error fetching created plugs: ", error);
        }
      );

      return () => unsubscribeCreated();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const plugsRef = collection(FIREBASE_DB, "plugs");
      const receivedPlugsQuery = query(
        plugsRef,
        where("friendsInvitedUIDs", "array-contains", user.uid)
      );
      const unsubscribeReceived = onSnapshot(
        receivedPlugsQuery,
        async (snapshot) => {
          setIsFirstLoad(true);
          const plugs = await Promise.all(
            snapshot.docs.map(async (doc) => {
              const data = doc.data();
              let plug = {
                creatorID: data.creatorID,
                creatorUsername: data.creatorUsername,
                icon: data.icon,
                title: data.title,
                date: data.date ? data.date.toDate() : null,
                placeName: data.placeName,
                placeAddress: data.placeAddress,
                startTime: data.startTime ? data.startTime.toDate() : null,
                endTime: data.endTime ? data.endTime.toDate() : null,
                description: data.description,
                friendsInvited: data.friendsInvited,
                friendsInvitedUIDs: data.friendsInvitedUIDs,
                friendsRSVP: data.friendsRSVP,
                status: data.status,
                createdAt: data.plugCreatedAt
                  ? data.plugCreatedAt.toDate()
                  : null,
                updatedAt: data.plugUpdatedAt
                  ? data.plugUpdatedAt.toDate()
                  : null,
                forwarding: data.forwarding,
                forwardedBy: data.forwardedBy,
                guestStatus: data.guestStatus,
                id: doc.id,
                longitude: data.longitude,
                latitude: data.latitude,
                plugPoint: data.plugPoint,
              };
              if (plug.status === "active") {
                const fiveAMNextDay = addHours(
                  startOfDay(addDays(plug.date, 1)),
                  5
                );
                if (isPast(fiveAMNextDay)) {
                  await updateDoc(doc.ref, {
                    // Here we pass the document reference directly
                    status: "ended",
                  });
                  plug.status = "ended";
                }
              }
              return plug;
            })
          );
          const filteredPlugs = plugs
            .filter((plug) => plug.friendsInvitedUIDs.includes(user.uid))
            .filter(
              (plug) =>
                !createdPlugs.some((createdPlug) => createdPlug.id === plug.id)
            );

          setReceivedPlugs(filteredPlugs);
        },
        (error) => {
          console.error("Error fetching received plugs: ", error);
        }
      );

      return () => unsubscribeReceived();
    }
  }, [user, createdPlugs]);

  useEffect(() => {
    if (user) {
      const templatesRef = collection(FIREBASE_DB, "templates");
      const plugsTemplateQuery = query(
        templatesRef,
        where("creatorID", "==", user.uid)
      );
      const unsubscribeTemplates = onSnapshot(
        plugsTemplateQuery,
        async (snapshot) => {
          const templates = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              creatorID: data.creatorID,
              icon: data.icon,
              title: data.title,
              placeName: data.placeName,
              placeAddress: data.placeAddress,
              description: data.description,
              friendsInvited: data.friendsInvited,
              groupsInvited: data.groupsInvited,
              forwarding: data.forwarding,
              guestStatus: data.guestStatus,
              longitude: data.longitude,
              latitude: data.latitude,
              id: doc.id,
            };
          });

          setPlugTemplates(templates);
        },
        (error) => {
          console.error("Error fetching plug templates: ", error);
        }
      );

      return () => unsubscribeTemplates();
    }
  }, [user]);

  useEffect(() => {
    if (isFirstLoad === true) {
      setIsFirstLoad(false);
    } else if (receivedPlugs.length > prevReceivedPlugsCount) {
      setUnreadReceivedPlugs(true);
    }
    setPrevReceivedPlugsCount(receivedPlugs.length);
  }, [receivedPlugs, prevReceivedPlugsCount, isFirstLoad]);

  const resetUnreadReceivedPlugs = () => {
    setUnreadReceivedPlugs(false);
  };

  const value = {
    createdPlugs,
    receivedPlugs,
    plugTemplates,
    createPlugInputs,
    savePlugInputs,
    forwardedBy,
    setCreatedPlugs,
    setReceivedPlugs,
    setPlugTemplates,
    setCreatePlugInputs,
    setSavePlugInputs,
    setForwardedBy,
    unreadReceivedPlugs,
    resetUnreadReceivedPlugs,
    chatMessages,
    updateChatMessages,
  };

  return (
    <PlugsContext.Provider value={value}>{children}</PlugsContext.Provider>
  );
};

export const PlugsContext = createContext<
  | {
      createdPlugs: Plug[];
      receivedPlugs: Plug[];
      plugTemplates: plugTemplates[];
      createPlugInputs: createPlugInputs | null;
      savePlugInputs: createPlugInputs | null;
      forwardedBy: forwardedBy | null;
      setCreatedPlugs: (plugs: Plug[]) => void;
      setReceivedPlugs: (plugs: Plug[]) => void;
      setPlugTemplates: (templates: plugTemplates[]) => void;
      setCreatePlugInputs: (details: createPlugInputs | null) => void;
      setSavePlugInputs: (details: createPlugInputs | null) => void;
      setForwardedBy: (forwarded: forwardedBy | null) => void;
      unreadReceivedPlugs: boolean;
      resetUnreadReceivedPlugs: () => void;
      chatMessages: Record<string, number>;
      updateChatMessages: (plugId: string, messagesCount: number) => void;
    }
  | undefined
>(undefined);

export const usePlugs = () => {
  const context = useContext(PlugsContext);

  if (context === undefined) {
    throw new Error("usePlugs must be used within a PlugsProvider");
  }

  return context;
};
