import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import appStoreBadge from "../assets/app-store-badge.png";
import playStoreBadge from "../assets/play-store-badge.png";
import CoolAppBar from "../components/AppBar";
import FullScreenBox from "../components/FullScreenBox";

const LandingPage = () => {
  return (
    <FullScreenBox>
      <CoolAppBar />
      <Container component="main" maxWidth="sm">
        <Typography variant="h1" component="h1" gutterBottom>
          Plug
        </Typography>
        <Typography variant="h5" component="h1" gutterBottom>
          Plan. Manage. Connect.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          No spam, promo, ads, unnecessary notifications, selling data, or
          in-app purchases.
          <br />
          <br />
          Just plans with friends, enjoy.
        </Typography>
        <a
          href="https://apps.apple.com/us/app/plug/id1473681218"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline-block", verticalAlign: "middle" }}
        >
          <img
            src={appStoreBadge}
            alt="Download on the App Store"
            style={{ margin: "10px", width: "250px", height: "95px" }}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.pluggo.plugapp"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline-block", verticalAlign: "middle" }}
        >
          <img
            src={playStoreBadge}
            alt="Get it on Google Play"
            style={{ margin: "10px", width: "250px", height: "110px" }}
          />
        </a>
      </Container>
    </FullScreenBox>
  );
};

export default LandingPage;
