import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import FullScreenBox from "../components/FullScreenBox";
import CoolAppBar from "../components/AppBar";
import {
  alpha,
  Box,
  CircularProgress,
  Container,
  Grid,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { getRandomEmoji } from "../utils/emojiUtils";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { FIREBASE_DB } from "../firebase";
import {
  DatePicker,
  LocalizationProvider,
  DesktopTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import dayjs from "dayjs";
import PageTitle from "../components/PageTitle";

const CreatePlug = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(
    new Date(new Date().setHours(new Date().getHours() + 1))
  );
  const [placeName, setPlaceName] = useState("");
  const [placeAddress, setPlaceAddress] = useState("");
  const [description, setDescription] = useState("");
  const [forwarding, setForwarding] = useState(true);
  const [guestStatus, setGuestStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  if (!user) {
    navigate("/login");
    return null;
  }

  const ninetyDaysFromNow = dayjs().add(90, "day").toDate();

  const handleCreatePlug = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const plug = {
        creatorID: user.uid,
        creatorUsername: user.displayName,
        icon: getRandomEmoji(),
        title: title,
        date: date,
        startTime: startTime,
        endTime: endTime,
        placeName: placeName || "Somewhere",
        placeAddress: placeAddress || "",
        longitude: "",
        latitude: "",
        description: description || "",
        friendsInvited: [],
        friendsInvitedUIDs: [],
        friendsRSVP: {},
        status: "active",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        forwarding: forwarding,
        guestStatus: guestStatus,
        forwardedBy: {},
        plugPoint: false,
      };
      await addDoc(collection(FIREBASE_DB, "plugs"), plug);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
    navigate("/home");
    setLoading(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FullScreenBox>
        <CoolAppBar />
        <PageTitle title="Create Plug" />
        <Container maxWidth="sm">
          <form noValidate autoComplete="off" onSubmit={handleCreatePlug}>
            <TextField
              label="Title (required)"
              variant="outlined"
              fullWidth
              margin="normal"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              disabled={loading}
              inputProps={{ maxLength: 45 }}
            />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={8} sm={4}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={(newValue) => setDate(newValue || new Date())}
                  disabled={loading}
                  views={["month", "day"]}
                  disablePast
                  maxDate={ninetyDaysFromNow}
                  sx={{
                    "& .Mui-disabled": {
                      color: alpha(theme.palette.error.main, 0.9),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <DesktopTimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newValue) => setStartTime(newValue || new Date())}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <DesktopTimePicker
                  label="End Time"
                  value={endTime}
                  onChange={(newValue) =>
                    setEndTime(
                      newValue ||
                        new Date(new Date().setHours(new Date().getHours() + 1))
                    )
                  }
                  disabled={loading}
                />
              </Grid>
            </Grid>
            <TextField
              label="Place Name (required)"
              variant="outlined"
              fullWidth
              margin="normal"
              value={placeName}
              onChange={(e) => setPlaceName(e.target.value)}
              disabled={loading}
              inputProps={{ maxLength: 45 }}
            />
            <TextField
              label="Address (optional)"
              variant="outlined"
              fullWidth
              margin="normal"
              value={placeAddress}
              onChange={(e) => setPlaceAddress(e.target.value)}
              disabled={loading}
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              label="Description (optional)"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={loading}
              inputProps={{ maxLength: 1500 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={guestStatus}
                  onChange={(event) => setGuestStatus(event.target.checked)}
                  disabled={loading}
                />
              }
              label="Show Guestlist"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={forwarding}
                  onChange={(event) => setForwarding(event.target.checked)}
                  disabled={loading}
                />
              }
              label="Allow Forwarding / Sharing"
            />
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!title || !placeName}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : "Create Plug"}
              </Button>
            </Box>
          </form>
        </Container>
      </FullScreenBox>
    </LocalizationProvider>
  );
};

export default CreatePlug;
