import * as React from "react";
import { ChangeEvent } from "react";
import {
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Typography,
  ButtonBase,
  Container,
  Chip,
  useTheme,
} from "@mui/material";
import { format, isToday, isTomorrow } from "date-fns";
import CoolAppBar from "./AppBar";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

interface Plug {
  creatorID: string;
  creatorUsername: string;
  icon: string;
  title: string;
  date: Date;
  placeName: string;
  placeAddress?: string;
  startTime: Date;
  endTime: Date;
  forwardedBy?: Record<string, string>;
  description?: string;
  friendsInvited: string[];
  friendsRSVP: {
    [username: string]: "accepted" | "likely" | "maybe" | "pending" | "ignored";
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  forwarding: boolean;
  guestStatus: boolean;
  id: string;
  longitude?: string | number;
  latitude?: string | number;
  plugPoint: boolean;
}

interface PlugsListProps {
  plugs: Plug[];
  activeList: string;
  handleChange: (event: ChangeEvent<{}>, newValue: string) => void;
  getCreatorDisplayName: (plug: Plug) => string;
}

const PlugsList: React.FC<PlugsListProps> = ({
  plugs,
  activeList,
  handleChange,
  getCreatorDisplayName,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!user) {
    navigate("/login");
    return null;
  }

  // const getRandomColor = () => {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  const getChipDetails = (
    status: string,
    isOwner: boolean,
    plug: Plug
  ): { color: string; label: string; textColor: string } => {
    if (plug.status === "ended") {
      return {
        color: theme.palette.action.disabledBackground,
        label: "Ended",
        textColor: theme.palette.text.disabled,
      };
    }

    if (isOwner)
      return {
        color: theme.palette.chipColors.pending,
        label: "Owner",
        textColor: theme.palette.text.primary,
      };

    switch (status) {
      case "accepted":
        return {
          color: theme.palette.chipColors.accepted,
          label: "Going",
          textColor: theme.palette.text.primary,
        };
      case "likely":
        return {
          color: theme.palette.chipColors.likely,
          label: "Likely",
          textColor: theme.palette.text.primary,
        };
      case "maybe":
        return {
          color: theme.palette.chipColors.maybe,
          label: "Maybe",
          textColor: theme.palette.text.primary,
        };
      case "pending":
        return {
          color: theme.palette.secondary.main,
          label: "RSVP",
          textColor: theme.palette.text.primary,
        };
      case "ignored":
        return {
          color: theme.palette.chipColors.maybe,
          label: "Hidden",
          textColor: theme.palette.text.primary,
        };
      default:
        return {
          color: theme.palette.chipColors.pending,
          label: "RSVP",
          textColor: theme.palette.text.primary,
        };
    }
  };

  return (
    <>
      <CoolAppBar />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            margin: "0 auto",
          }}
        >
          <Tabs
            value={activeList}
            onChange={handleChange}
            aria-label="Plug filtering tabs"
            TabIndicatorProps={{ style: { borderRadius: "5px 5px 0 0" } }}
          >
            <Tab label="Received" value="received" sx={{ borderRadius: 10 }} />
            <Tab label="All" value="all" sx={{ borderRadius: 10 }} />
            <Tab label="Created" value="created" sx={{ borderRadius: 10 }} />
          </Tabs>
        </Box>
        <List
          sx={{
            mx: "auto",
            width: "100%",
            overflow: "auto",
          }}
        >
          {plugs.length > 0 ? (
            plugs.map((plug) => {
              let displayDate: string;
              if (isToday(plug.date)) {
                displayDate = "Today";
              } else if (isTomorrow(plug.date)) {
                displayDate = "Tomorrow";
              } else {
                displayDate = format(plug.date, "E MMM do,");
              }
              //   let creatorDisplayName = getCreatorDisplayName(plug);
              const userStatus = user
                ? plug.friendsRSVP[user.uid] || "pending"
                : "pending";
              const isOwner = user ? plug.creatorID === user.uid : false;
              const chipDetails = getChipDetails(userStatus, isOwner, plug);
              return (
                <React.Fragment key={plug.id}>
                  <Link
                    to={`/plug/${plug.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <ListItem
                      alignItems="center"
                      component={ButtonBase}
                      sx={{
                        borderRadius: 5,
                      }}
                    >
                      <ListItemAvatar>
                        <Typography variant="h3">{plug.icon}</Typography>
                      </ListItemAvatar>
                      <ListItemText
                        primary={plug.title}
                        secondary={
                          <>
                            {`${displayDate} ${format(
                              plug.startTime,
                              "hh:mm a"
                            )}`}
                            <br />
                            {`${plug.placeName} ${getCreatorDisplayName(plug)}`}
                          </>
                        }
                      />
                      <Chip
                        label={chipDetails.label}
                        style={{
                          backgroundColor: chipDetails.color,
                          color: chipDetails.textColor,
                        }}
                      />
                    </ListItem>
                  </Link>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              );
            })
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                textAlign: "center",
                color: "grey.500",
                fontStyle: "italic",
                mt: 4,
              }}
            >
              No plugs to show.
            </Typography>
          )}
        </List>
      </Container>
    </>
  );
};

export default PlugsList;
