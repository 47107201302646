import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/PlugLogo.png";
import { signOut } from "firebase/auth";
import { FIREBASE_AUTH } from "../firebase";
import { AuthContext } from "../contexts/AuthContext";
import { useTheme } from "@mui/material/styles";

function CoolAppBar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(FIREBASE_AUTH); // Replace 'auth' with your Firebase auth instance
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <Link to="/">
                <img src={logo} alt="Plug logo" height="50px" />
              </Link>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {user && (
                  <>
                    <MenuItem
                      component={Link}
                      to="/home"
                      sx={{ py: "6px", px: "12px", borderRadius: "10px" }}
                    >
                      <Typography variant="body2" color="text.primary">
                        Plugs
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/create-plug"
                      sx={{ py: "6px", px: "12px", borderRadius: "10px" }}
                    >
                      <Typography variant="body2" color="text.primary">
                        Create Plug
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/past-plugs"
                      sx={{ py: "6px", px: "12px", borderRadius: "10px" }}
                    >
                      <Typography variant="body2" color="text.primary">
                        Past Plugs
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/account"
                      sx={{ py: "6px", px: "12px", borderRadius: "10px" }}
                    >
                      <Typography variant="body2" color="text.primary">
                        Account
                      </Typography>
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  component={Link}
                  to="/patch-notes"
                  sx={{ py: "6px", px: "12px", borderRadius: "10px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Patch Notes
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {user ? (
                <>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.palette.text.secondary,
                      fontWeight: "bold",
                    }}
                  >
                    {user.displayName}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: theme.palette.subtext.main,
                      fontStyle: "italic",
                      marginRight: theme.spacing(2),
                    }}
                  >
                    is in the building
                  </Typography>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={handleLogout}
                  >
                    Log Out
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  onClick={() => navigate("/login")}
                >
                  Log in / Sign up
                </Button>
              )}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  ></Box>
                  {user && (
                    <>
                      <MenuItem component={Link} to="/home">
                        Plugs
                      </MenuItem>
                      <MenuItem component={Link} to="/create-plug">
                        Create Plug
                      </MenuItem>
                      <MenuItem component={Link} to="/past-plugs">
                        Past Plugs
                      </MenuItem>
                      <MenuItem component={Link} to="/account">
                        Account
                      </MenuItem>
                    </>
                  )}
                  <MenuItem component={Link} to="/patch-notes">
                    Patch Notes
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    {user ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: theme.palette.text.secondary,
                            fontWeight: "bold",
                          }}
                        >
                          {user.displayName}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.subtext.main,
                            fontStyle: "italic",
                            marginBottom: theme.spacing(2),
                          }}
                        >
                          is in the building
                        </Typography>
                        <Button
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={handleLogout}
                        >
                          Log Out
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        color="primary"
                        variant="text"
                        size="small"
                        onClick={() => navigate("/login")}
                      >
                        Log in / Sign up
                      </Button>
                    )}
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default CoolAppBar;
