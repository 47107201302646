import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Link,
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import { FIREBASE_AUTH } from "../firebase";
import { signInWithCustomToken } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../App.css";
import IconButton from "@mui/material/IconButton";
import logo from "../assets/PlugLogo.png";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  sendVerificationCode,
  checkVerificationCode,
  checkIfUserExists,
  createNewUser,
} from "../utils/cloudFunctions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { getRandomEmoji } from "../utils/emojiUtils";
import FullScreenBox from "../components/FullScreenBox";
import { useParams } from "react-router-dom";

const LoginPage = () => {
  let { plugId } = useParams();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [userExists, setUserExists] = useState(false);
  // const [isFormValid, setIsFormValid] = useState(false);
  const [openAlert, setAlertOpen] = useState(false);
  const navigate = useNavigate();
  const defaultIcon = getRandomEmoji();
  const [alertMessage, setAlertMessage] = useState("Success!");

  // Similar logic as your mobile app
  // useEffect(() => {
  //   if (userExists) {
  //     setIsFormValid(verificationCode !== "");
  //   } else {
  //     setIsFormValid(
  //       agreedToTerms && verificationCode !== "" && displayName !== ""
  //     );
  //   }
  // }, [agreedToTerms, verificationCode, displayName, userExists]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const handleSendVerificationCode = async () => {
    console.log("phone number", phoneNumber);
    setLoading(true);
    try {
      const sendResult = await sendVerificationCode(phoneNumber);
      console.log(sendResult);
      const userExists = await checkIfUserExists(phoneNumber);
      setUserExists(userExists);
      setVerificationSent(true);
    } catch (error) {
      console.error("Error sending verification or checking user existance");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    setLoading(true);
    try {
      let result;
      try {
        result = await checkVerificationCode(phoneNumber, verificationCode);
      } catch (error) {
        throw new Error(`Incorrect verification code`);
      }

      if ((result as { success: boolean; customToken: string }).success) {
        setAlertOpen(true);

        // If the user does not exist, create a new user
        if (!userExists) {
          try {
            const userData = {
              phoneNumber: phoneNumber,
              displayName: displayName,
              icon: defaultIcon,
            };
            await createNewUser(userData);
          } catch (error) {
            throw new Error(`Error creating new user`);
          }
        }

        // Sign in the user with the custom token
        try {
          await signInWithCustomToken(
            FIREBASE_AUTH,
            (result as { success: boolean; customToken: string }).customToken
          );
          plugId ? navigate(`/plug/${plugId}`) : navigate("/home");
        } catch (error) {
          throw new Error(`Error signing in`);
        }
      } else {
        setAlertMessage("Verification failed");
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage("Verification failed");
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FullScreenBox>
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => navigate("/")}
        sx={{ position: "absolute", top: 25, left: 25 }} // Add this
      >
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Plug logo" height="150px" />
          <PhoneInput
            defaultCountry="US"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={(value: string | undefined) =>
              setPhoneNumber(value || "")
            }
            countries={["US", "CA", "MX", "GB", "AU", "IE", "NZ", "ZA", "NL"]}
            disabled={verificationSent || loading}
          />
          <Typography variant="body2" style={{ marginBottom: "16px" }}>
            {verificationSent
              ? "A verification code has been sent."
              : "Message and data rates may apply."}
          </Typography>
          {verificationSent ? (
            <>
              {!userExists && (
                <TextField
                  variant="outlined"
                  label="Name (you can change this later)"
                  value={displayName}
                  onChange={(event) => setDisplayName(event.target.value)}
                  autoComplete="name"
                  autoCapitalize="words"
                  autoCorrect="on"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  inputProps={{ maxLength: 30 }}
                />
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                id="verificationCode"
                label="Verification Code"
                name="verificationCode"
                autoComplete="one-time-code"
                fullWidth
                autoFocus
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                disabled={loading}
              />
              {!userExists && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    mb: 3,
                    mt: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreedToTerms}
                        onChange={() => setAgreedToTerms(!agreedToTerms)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography>
                        I am 13 years of age or older and agree to the
                        <Link
                          href="https://app.termly.io/document/terms-of-service/6b0344c4-0bd2-472a-854e-9cc1571ce346"
                          color="primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" Terms "}
                        </Link>
                        and
                        <Link
                          href="https://app.termly.io/document/privacy-policy/52366e47-0496-4871-a8f7-cf6c0472b827#contact"
                          color="primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" Privacy Policy"}
                        </Link>
                        .
                      </Typography>
                    }
                  />
                </Box>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={loading || !isValidPhoneNumber(phoneNumber)}
              onClick={handleSendVerificationCode}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                "Send Verification Code"
              )}
            </Button>
          )}

          {verificationSent && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                loading ||
                (!userExists && !agreedToTerms) ||
                verificationCode.length !== 6 ||
                (!userExists && !displayName)
              }
              onClick={handleVerifyCode}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : userExists ? (
                "Verify Code"
              ) : (
                "Sign Up"
              )}
            </Button>
          )}
          {verificationSent && (
            <Button
              variant="text"
              onClick={() => {
                setVerificationSent(false);
                setUserExists(false);
                setVerificationCode("");
              }}
              style={{ marginTop: "20px" }}
              disabled={loading}
            >
              Resend verification code
            </Button>
          )}
        </Box>
      </Container>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>
    </FullScreenBox>
  );
};

export default LoginPage;
