// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmJgOe33M2BUhw3lAOJ2jTwAYFSZnwb2Q",
  authDomain: "plug2-26d60.firebaseapp.com",
  projectId: "plug2-26d60",
  storageBucket: "plug2-26d60.appspot.com",
  messagingSenderId: "226835206740",
  appId: "1:226835206740:web:a503cb9dd2297500f193c1",
  measurementId: "G-VDDXZWGD0Y",
};

// Initialize Firebase
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);
export const FIREBASE_DB = getFirestore(FIREBASE_APP);
// const analytics = getAnalytics(app);
