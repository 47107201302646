import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { usePlugs } from "../contexts/PlugsContext";
import { useAuth } from "../contexts/AuthContext";
import { arrayUnion, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { FIREBASE_DB } from "../firebase";
import { Plug } from "../types/types";
import { useNavigate } from "react-router-dom";
import FullScreenBox from "../components/FullScreenBox";
import CoolAppBar from "../components/AppBar";
import Container from "@mui/material/Container";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Link as MUILink,
} from "@mui/material";
import { sendNotificationToUser } from "../utils/cloudFunctions";
import React from "react";
import {
  CalendarToday,
  FileCopy,
  Group,
  LocationOn,
  Person,
} from "@mui/icons-material";
// @ts-ignore
import Linkify from "react-linkify";
import { format, isToday, isTomorrow } from "date-fns";

const PlugDetails = () => {
  const { plugId } = useParams();
  // let plugId = "9CDcz3VfeWXCo1P9XEnx";
  const { createdPlugs, receivedPlugs } = usePlugs();
  const { user } = useAuth();
  const [plug, setPlug] = useState<Plug | null>(null);
  const ignoredPlug =
    (plug?.id && user?.ignoredPlugs?.includes(plug.id)) || false;
  const isCreator = plug?.creatorID === user?.uid;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [userStatus, setUserStatus] = useState(
    user && user.uid && plug?.friendsRSVP[user.uid]
      ? plug.friendsRSVP[user.uid]
      : "pending"
  );
  const [isPrivatePlug, setIsPrivatePlug] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!plugId) {
      navigate("/");
      return;
    }
    // Try to find the plug in the context
    const plugFromContext =
      createdPlugs.find((plug) => plug.id === plugId) ||
      receivedPlugs.find((plug) => plug.id === plugId);

    if (plugFromContext) {
      setPlug(plugFromContext);
    } else {
      setPageLoading(true);
      const plugRef = doc(FIREBASE_DB, "plugs", plugId);
      const unsubscribe = onSnapshot(
        plugRef,
        (doc) => {
          setPageLoading(false);
          if (doc.exists()) {
            const data = doc.data();
            const plug: Plug = {
              creatorID: data.creatorID,
              creatorUsername: data.creatorUsername,
              icon: data.icon,
              title: data.title,
              date: data.date ? data.date.toDate() : null,
              placeName: data.placeName,
              placeAddress: data.placeAddress,
              startTime: data.startTime ? data.startTime.toDate() : null,
              endTime: data.endTime ? data.endTime.toDate() : null,
              description: data.description,
              friendsInvited: data.friendsInvited,
              friendsInvitedUIDs: data.friendInvitedUIDs,
              friendsRSVP: data.friendsRSVP,
              status: data.status,
              createdAt: data.plugCreatedAt
                ? data.plugCreatedAt.toDate()
                : null,
              updatedAt: data.plugUpdatedAt
                ? data.plugUpdatedAt.toDate()
                : null,
              forwarding: data.forwarding,
              guestStatus: data.guestStatus,
              id: doc.id,
              longitude: data.longitude,
              latitude: data.latitude,
              plugPoint: data.plugPoint,
            };
            if (!data.forwarding && !user) {
              setIsPrivatePlug(true);
              return;
            }
            setPlug(plug);
          } else {
            console.error("No such document!");
          }
        },
        (error) => {
          setPageLoading(false);
          console.error("Error fetching plug: ");
        }
      );

      return () => unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plugId, createdPlugs, receivedPlugs]);

  if (pageLoading) {
    return (
      <div>
        <CircularProgress />
        <Typography variant="h6">Fetching Plug details</Typography>
      </div>
    );
  }

  if (isPrivatePlug) {
    return (
      <Typography variant="body1">
        This is a private plug. Please <Link to="/login">log in</Link> to check
        if you are invited.
      </Typography>
    );
  }

  const updateRSVPStatus = async (
    plugID: string,
    username: string,
    newStatus: string
  ) => {
    if (!user || !user.uid) {
      console.error("Something went wrong with RSVP status update.");
      return;
    }

    setLoading(true);
    const plugRef = doc(FIREBASE_DB, "plugs", plugID);
    const userRef = doc(FIREBASE_DB, "users", user?.uid);
    const updateData = {
      [`friendsRSVP.${username}`]: newStatus,
      friendsInvitedUIDs: arrayUnion(user?.uid),
    };

    try {
      await updateDoc(plugRef, updateData);
      setUserStatus(
        newStatus as "accepted" | "likely" | "maybe" | "pending" | "ignored"
      );
      setPlug((oldPlug: Plug | null) => {
        if (!oldPlug) {
          // Handle the case where oldPlug is null
          console.error("oldPlug is null");
          return null;
        }

        return {
          ...oldPlug,
          friendsRSVP: {
            ...oldPlug.friendsRSVP,
            [username]: newStatus as
              | "accepted"
              | "likely"
              | "maybe"
              | "pending"
              | "ignored",
          },
        };
      });

      if (newStatus === "accepted") {
        if (!plug) {
          console.error("Something went wrong with loading the plug.");
          return;
        }

        sendNotificationToUser(
          plug.creatorID,
          plug.creatorUsername,
          `${user.displayName} accepted your Plug`,
          `${plug.icon} ${plug.title}`,
          "PlugDetailsScreen",
          plug
        );
      }

      if (ignoredPlug && newStatus !== "ignored") {
        const newIgnoredPlugs = user.ignoredPlugs?.filter(
          (id) => id !== plugID
        );
        await updateDoc(userRef, { ignoredPlugs: newIgnoredPlugs });
      } else if (!ignoredPlug && newStatus === "ignored") {
        const newIgnoredPlugs = [...(user.ignoredPlugs || []), plugID];
        await updateDoc(userRef, { ignoredPlugs: newIgnoredPlugs });
      }
    } catch (err) {
      console.error("Error updating RSVP status: ", err);
    }
    setLoading(false);
  };

  const getChipDetails = (
    status: string,
    isOwner: boolean,
    isIgnored: boolean
  ): { color: string; label: string } => {
    if (isOwner)
      return {
        color: theme.palette.secondary.main,
        label: "Owner",
      };

    if (isIgnored)
      return {
        color: theme.palette.chipColors.maybe,
        label: "Hidden",
      };

    switch (status) {
      case "accepted":
        return {
          color: theme.palette.chipColors.accepted,
          label: "Going",
        };
      case "likely":
        return {
          color: theme.palette.chipColors.likely,
          label: "Likely",
        };
      case "maybe":
        return {
          color: theme.palette.chipColors.maybe,
          label: "Maybe",
        };
      case "pending":
        return {
          color: theme.palette.secondary.main,
          label: "RSVP",
        };
      case "ignored":
        return {
          color: theme.palette.chipColors.maybe,
          label: "Hidden",
        };
      default:
        return {
          color: theme.palette.secondary.main,
          label: "RSVP",
        };
    }
  };

  const chipDetails = getChipDetails(userStatus, isCreator, ignoredPlug);

  const handleClose = () => {
    setMenuAnchor(null);
  };

  if (!plug) {
    console.error("Something went wrong with the Plug");
    return null;
  }

  const handleCopy = async () => {
    try {
      const urlToCopy = `https://pluggo.app/plug/${plug.id}`;
      await navigator.clipboard.writeText(urlToCopy);
      alert("Link to Plug copied to clipboard");
    } catch (err) {
      console.error("Failed to copy URL: ", err);
    }
  };

  let displayDate: string;
  const date = new Date(plug.date);
  if (isToday(date)) {
    displayDate = "Today";
  } else if (isTomorrow(date)) {
    displayDate = "Tomorrow";
  } else {
    displayDate = format(date, "E MMM do,");
  }

  const linkDecorator = (href: string, text: string, key: number) => (
    <MUILink
      href={href}
      key={key}
      style={{ color: theme.palette.hyperlink.main }}
      underline="none"
    >
      {text}
    </MUILink>
  );

  return (
    <FullScreenBox>
      <CoolAppBar />
      <Container component="main" maxWidth="xs">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            fontWeight: "bold",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          {plug.title}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={3}
          mb={3}
        >
          {plug.status === "ended" ? (
            <Typography
              variant="h5"
              style={{ color: theme.palette.text.disabled, fontWeight: "bold" }}
            >
              This plug has ended
            </Typography>
          ) : (
            <>
              <Chip
                label={
                  loading ? (
                    <CircularProgress size={14} />
                  ) : user ? (
                    chipDetails.label
                  ) : (
                    "Log in to RSVP"
                  )
                }
                style={{
                  backgroundColor: chipDetails.color,
                }}
                onClick={(event) => {
                  if (!user) {
                    navigate(`/login/${plug.id}`);
                  } else if (!isCreator && !loading) {
                    setMenuAnchor(event.currentTarget);
                  }
                }}
              />
              {user && (
                <Menu
                  id="simple-menu"
                  anchorEl={menuAnchor}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      updateRSVPStatus(plug.id, user.uid, "accepted");
                      handleClose();
                    }}
                  >
                    Going
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      updateRSVPStatus(plug.id, user.uid, "likely");
                      handleClose();
                    }}
                  >
                    Likely
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      updateRSVPStatus(plug.id, user.uid, "maybe");
                      handleClose();
                    }}
                  >
                    Maybe
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      updateRSVPStatus(plug.id, user.uid, "ignored");
                      handleClose();
                    }}
                  >
                    Hide
                  </MenuItem>
                </Menu>
              )}
              <Box ml={3}>
                {plug.forwarding ? (
                  <Button
                    variant="text"
                    style={{ color: theme.palette.hyperlink.main }}
                    endIcon={<FileCopy />}
                    onClick={handleCopy}
                    size="small"
                  >
                    Copy link
                  </Button>
                ) : (
                  <Typography
                    variant="body1"
                    style={{
                      fontStyle: "italic",
                      color: theme.palette.subtext.main,
                    }}
                  >
                    Private Plug
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <Person
            style={{
              fontSize: 35,
              color: theme.palette.secondary.contrastText,
            }}
          />
          <Typography variant="body1" ml={2}>
            Created by {plug.creatorUsername}
            {isCreator && user && plug.creatorID !== user.uid && (
              <Typography
                color="textSecondary"
                component="span"
                style={{ fontStyle: "italic" }}
              >
                {" "}
                (You're an admin)
              </Typography>
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <CalendarToday
            style={{
              fontSize: 35,
              color: theme.palette.secondary.contrastText,
            }}
          />
          <Typography variant="body1" ml={2}>
            {displayDate} {format(plug.startTime, "hh:mm a")} -{" "}
            {format(plug.endTime, "hh:mm a")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <LocationOn
            style={{
              fontSize: 35,
              color: theme.palette.secondary.contrastText,
            }}
          />
          <Box display="flex" flexDirection="column" ml={2}>
            <Typography variant="body1">{plug.placeName}</Typography>
            {plug.placeAddress && (
              <MUILink
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  plug.placeAddress
                )}`}
                target="_blank"
                underline="none"
              >
                <Typography style={{ color: theme.palette.hyperlink.main }}>
                  {plug.placeAddress}
                </Typography>
              </MUILink>
            )}
          </Box>
        </Box>
        {plug.guestStatus !== false || isCreator ? (
          <Box display="flex" alignItems="center" mt={2}>
            <Group
              style={{
                fontSize: 35,
                color: theme.palette.secondary.contrastText,
              }}
            />
            <Box display="flex" flexDirection="row" ml={2}>
              <Typography variant="body1" mr={2}>
                {
                  Object.values(plug.friendsRSVP).filter(
                    (status) => status === "accepted"
                  ).length
                }{" "}
                Going
              </Typography>
              <Typography variant="body1" mr={2}>
                {
                  Object.values(plug.friendsRSVP).filter(
                    (status) => status === "likely"
                  ).length
                }{" "}
                Likely
              </Typography>
              <Typography variant="body1" mr={2}>
                {
                  Object.values(plug.friendsRSVP).filter(
                    (status) => status === "maybe"
                  ).length
                }{" "}
                Maybe
              </Typography>
              {isCreator && (
                <Typography variant="body1">
                  {
                    Object.values(plug.friendsRSVP).filter(
                      (status) => status === "pending"
                    ).length
                  }{" "}
                  Pending
                </Typography>
              )}
            </Box>
          </Box>
        ) : null}
        <Box mt={4}>
          <Typography variant="body1">
            {" "}
            <Linkify componentDecorator={linkDecorator}>
              {plug.description}
            </Linkify>
          </Typography>
        </Box>
        <Box mt={7}>
          <Typography
            variant="body1"
            style={{
              fontStyle: "italic",
              color: theme.palette.subtext.main,
            }}
          >
            Download on{" "}
            <MUILink
              href="https://apps.apple.com/us/app/plug/id1473681218"
              target="_blank"
              underline="none"
              style={{ color: theme.palette.hyperlink.main }}
            >
              iOS
            </MUILink>{" "}
            or{" "}
            <MUILink
              href="https://play.google.com/store/apps/details?id=com.pluggo.plugapp"
              target="_blank"
              underline="none"
              style={{ color: theme.palette.hyperlink.main }}
            >
              Android
            </MUILink>{" "}
            for a better experience
          </Typography>
        </Box>
      </Container>
    </FullScreenBox>
  );
};

export default PlugDetails;
