const emojiList = [
  "😃",
  "😄",
  "😅",
  "🙃",
  "🔌",
  "🚀",
  "🥑",
  "🥶",
  "🌽",
  "🍿",
  "⛳️",
  "🍞",
  "🥖",
  "🧠",
  "🤑",
  "🔥",
  "📍",
  "🫡",
  "🫵",
  "🦦",
  "🧼",
  "🧽",
  "✈️",
  "🚁",
  "🛬",
  "👀",
  "🙌",
  "🌳",
  "🌱",
  "🫧",
];

export function getRandomEmoji() {
  const randomIndex = Math.floor(Math.random() * emojiList.length);
  return emojiList[randomIndex];
}

export const getEmoji = (letter: string) => {
  interface LetterToEmoji {
    [key: string]: string;
  }

  const letterToEmoji: LetterToEmoji = {
    A: "🍎", // Apple
    B: "🐝", // Bee
    C: "🐱", // Cat
    D: "🐶", // Dog
    E: "🐘", // Elephant
    F: "🐠", // Fish
    G: "🍇", // Grapes
    H: "🏠", // House
    I: "🍦", // Ice Cream
    J: "🎵", // Jingle
    K: "🔑", // Key
    L: "🦁", // Lion
    M: "🌝", // Moon
    N: "👃", // Nose
    O: "🐙", // Octopus
    P: "🍍", // Pineapple
    Q: "👸", // Queen
    R: "🌈", // Rainbow
    S: "🌞", // Sun
    T: "🐢", // Turtle
    U: "☂️", // Umbrella
    V: "🌋", // Volcano
    W: "🌊", // Wave
    X: "❌", // X mark
    Y: "🍋", // Yellow Lemon
    Z: "🦓", // Zebra
    OTHER: "🔰", // Default emoji
  };

  if (!/^[A-Z]$/i.test(letter)) {
    return letterToEmoji["OTHER"];
  }

  return letterToEmoji[letter.toUpperCase()] || letterToEmoji["OTHER"];
};
