import { Typography } from "@mui/material";

interface PageTitleProps {
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => (
  <Typography variant="h4">{title}</Typography>
);

export default PageTitle;
