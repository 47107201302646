import React, { createContext, useEffect, useState, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { FIREBASE_AUTH, FIREBASE_DB } from "../firebase";

interface User {
  uid: string;
  phoneNumber: string;
  displayName: string;
  icon: string;
  ignoredPlugs: string[];
  pushToken: string;
}

interface AuthProps {
  user: User | null;
  initialized: boolean;
}

export const AuthContext = createContext<AuthProps>({
  user: null,
  initialized: false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, (userAuth) => {
      if (userAuth) {
        // subscribe to user document
        const userUnsubscribe = onSnapshot(
          doc(FIREBASE_DB, "users", userAuth.uid),
          (userDoc) => {
            if (userDoc.exists()) {
              const userData = userDoc.data() as User;
              setUser({
                ...userData,
                uid: userAuth.uid,
              });
            }
            setInitialized(true);
          },
          (error) => {
            console.error("Error in Auth Context snapshot listener:", error);
          }
        );

        // Clean up the subscription on unmount
        return () => {
          userUnsubscribe();
        };
      } else {
        setUser(null);
      }
      setInitialized(true);
    });

    // Clean up the subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        initialized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
